import Spacer from '@components/shared/Spacer';
import { Container, Link, Theme, Typography } from '@material-ui/core';
import { withStyles, WithStyles } from '@material-ui/styles';
import React from 'react';

const styles = (theme: Theme) => ({
  container: {
    color: theme.palette.text.primary
  },
  title: {
    fontWeight: 600
  },
  bodyText: {
    [theme.breakpoints.up('sm')]: {
      width: '90%'
    }
  }
});

const Company: React.FC<WithStyles<typeof styles>> = ({ classes }) => (
  <Container className={classes.container}>
    <Typography className={classes.title} variant="h4">
      Är du förlag, utbildningsanordnare eller bara generellt behöver hjälp med
      att digitalisera innehåll?{' '}
      <Typography variant="inherit" color="primary">
        Låt oss hjälpa dig!
      </Typography>
    </Typography>
    <Spacer spacing={4} />
    <Typography className={classes.title} variant="h5">
      Distribuera material på Easy Education:
    </Typography>
    <Typography className={classes.bodyText} variant="h6">
      Vill ni tillgängliggöra litteratur till fler studenter med ett smart
      interaktivt gränssnitt? Gör detta via Easy Education.
    </Typography>
    <Typography className={classes.bodyText} variant="h6">
      Genom digital distribution undviker ni onödiga kostnader, andrahands
      försäljning samt kan erbjuda mervärden i kombination med litteraturen.
      Digitaliseringen möjliggör också unik data insamling för studenternas
      användar- och läsbeteende.
    </Typography>
    <Spacer spacing={4} />
    <Typography className={classes.title} variant="h5">
      Konvertering{' '}
    </Typography>
    <Typography className={classes.bodyText} variant="h6">
      Vi hjälper dig med automatisk konvertering av gamla textformat till ett
      smart, interaktivt och digitalt format (Hyper Text Markup Language). Så
      att ni kan erbjuda mervärde till era kunder & partners. På era befintliga
      plattformar och kanaler.
    </Typography>
    <Spacer spacing={4} />
    <Typography className={classes.title} variant="h5">
      Hosting
    </Typography>
    <Typography className={classes.bodyText} variant="h6">
      Vi hjälper er, eller era kunder, att tillhandahålla det konverterade
      materialet till slutanvändare på ett snabbt, sömlöst och användarvänligt
      sätt. Vi kostnads optimerar också lagring av materialet.
    </Typography>
    <Spacer spacing={4} />
    <Typography className={classes.title} variant="h5">
      Dataplattform
    </Typography>
    <Typography className={classes.bodyText} variant="h6">
      Vi tillhandahåller data som möjliggör för er, eller era kunder att ta del
      av djupgående insikter om hur slutanvändare tar del av och arbetar med er
      litteratur. Ett komplement som kan hjälpa er, författare, eller
      utbildningsanordnare att utveckla och förbättra sina värdeerbjudanden.
    </Typography>
    <Spacer spacing={4} />
    <Typography className={classes.bodyText} variant="h6">
      För mer info kontakta oss:{' '}
      <Link href="mailto:info@easyeducation.se" target="__blank">
        info@easyeducation.se
      </Link>
    </Typography>
  </Container>
);

export default withStyles(styles)(Company);
